import React from 'react';
import "../../pages/techsupport/techsupport.css"
import techimage from "./techsupportimage.png";
import { Helmet } from "react-helmet";
import { SecondHeader } from '../../../../components/header';
import Footer from '../../../../components/footer';
import phoneicon from './phonenumber.png'
import csroffers from './whatcsroffers-image.png'

function Techsupport() {
  return (
    <>
    <SecondHeader />
    <section className="it-support-hero">
       <Helmet>
        <title> Tech Support| CSR Limited</title>
      </Helmet>
      <div className="container">
        <div className="row">

        <div className="col1">
            <h1 className="heading">Bringing Cutting Edge Tech support and Effective Communication.</h1>
            <p>
              CSR brings in proven support practices to greatly reduce reoccurrences, 
              resulting in your company's lowest possible IT Tech downtime.
            </p>
            <a className="quick-support">
              <p className="title">FOR QUICK SUPPORT CALL US ON</p>
              <div className="phone-number">
                 <img src={phoneicon} alt="" />
                <p>+250 791 902 159</p>
              </div>
              <div className="phone-number">
                 <img src={phoneicon} alt="" />
                <p>+250 791 902 159</p>
              </div>
            </a>
          </div>


          <div className='col2'>
             <img src={techimage} alt="" />
          </div>
        </div>
      </div>
      
      <section className="about-the-company">
        <div className="container">
          <h1 className="heading">About the company</h1>
          <p>
            CSR offers a perfect blend of technology and people. Our IT department has several 
            IT solutions that has enabled us and our experts to deliver exceptional service to 
            our customers. We promptly believe that technology should be an enabler with the 
            intention to provide accurate insights and data, allowing our team to offer superior 
            quality work done to customers all over Rwanda.
          </p>
          {/* <a href="/contact/">
            <button className="btn-blue btn-header">Get a quotation</button>
          </a> */}
        </div>
      </section>

      <section className="what-we-offer">
        <div className="container-row">

          <div className="main-row">
          <div className="col-col-img">
            <img src={csroffers} alt="" />
          </div>
          <div className="col-col-text">
            <h1 className="heading">What we offer</h1>
            <div className="offer troubleshooting">
              <h4 className="offer-title">Various IT solutions</h4>
              <p>
                Identifying hardware and software solutions involves a thorough analysis of business needs, followed by selecting suitable components to ensure optimal integration and support.
              </p>
            </div>
            <div className="offer-software">
              <h4 className="offer-title">Speaking to customers</h4>
              <p>
                Engaging with customers directly to swiftly identify and address the core of their issues. Efficiently resolving problems by actively communicating and understanding customer concerns for effective problem-solving.
              </p>
            </div>
            <div className="offer-network">
              <h4 className="offer-title">Testing and evaluating new technologies</h4>
              <p>
                Conducting rigorous testing and evaluation of emerging technologies to assess their suitability for implementation. Ensuring informed decision-making by thoroughly analyzing the performance, compatibility, and potential benefits of new technologies.
              </p>
            </div>
          </div>
          </div>
      

        <div className="container-row-row-text">
          <div className="text">
            <div className="number-title">
              <div className="number">
                <p>1</p>
              </div>
              <h4>On-site Visits</h4>
            </div>
            <p>
              We offer on-site visits for local companies with IT issues, ensuring rapid resolution and superior quality of work.
            </p>
          </div>
          
          <div className="text">
            <div className="number-title">
              <div className="number">
                <p>2</p>
              </div>
              <h4>Handling multiple Systems</h4>
            </div>
            <p>
              We support your team regardless of tools and devices. Onsite or virtual server installs, and ecosystem transitions.
            </p>
          </div>
          
          <div className="text">
            <div className="number-title">
              <div className="number">
                <p>3</p>
              </div>
              <h4>Service for everyone</h4>
            </div>
            <p>
              We serve diverse industries, focusing on small-to-medium businesses (SMBs).
            </p>
          </div>
        </div>

        </div>
      </section>

      <section className="faq">
        <div className="container-row">
          <div className="questions">
            <h1 className='question-title'>Frequently Asked Questions</h1>
            <details>
              <summary>What are Managed Service Providers (MSPs)?</summary>
              <p>
                Managed Service Providers (MSPs) are the outsourced third-party company 
                that manages your IT requirements and functions. MSPs are strategic partners 
                who handle the company’s IT operations by enabling IT agility and lowering fixed 
                costs. Outsourcing to managed service providers is becoming a growing trend as more companies are realizing the many benefits of doing so.
              </p>
            </details>
            <details>
              <summary>What Is Your Response Time?</summary>
              <p>
                We try to resolve issues as soon as possible. Our IT help desk is always available and depending on the severity of the issue a client is facing, we ensure fast response times. Our goal is to meet your IT needs and build a proactive, robust and smart IT infrastructure that will minimize disruptions and downtime.
              </p>
            </details>
            <details>
              <summary>What if we already have an internal IT department?</summary>
              <p>
                We have worked with numerous internal IT departments on project bases or in managing day-to-day operations. We work well with others, we promise.
              </p>
            </details>
            <details>
              <summary>What is the cost of your services?</summary>
              <p>
                It really depends on what service levels you need and what you are looking to achieve. Although every business needs IT in today’s fast-paced world, we do have different tiered structures that offer different service levels, which define hours, fees, and response times. We will work within the framework of your needs, budget, and requirements.
              </p>
            </details>
            <details>
              <summary>How long does a managed servicing contract last?</summary>
              <p>
                If we are managing your day-to-day IT operations, we draft monthly service level agreements (SLAs) with our clients. If we are working on a specific, short-term project – then the contract will cover the time it requires us to complete the project.
              </p>
            </details>
            <details>
              <summary>Why Comprehensive staffing resources?</summary>
              <p>
                With over 10 well-trained and experienced IT personnel, CSR has been able to navigate the ever-changing world of IT by being able to keep ahead of the curve and retool. Our clients have benefited from our best-in-the-industry and next-generation solutions, as we deliver on time, on budget and with your business goals in mind.
              </p>
            </details>
            <details>
              <summary>Why does my company need a Managed Service Provider?</summary>
              <p>
                In today’s interconnected and fast-paced world, IT is an essential part of the business. Businesses of all industries and sizes must be able to manage their IT requirements if they want to improve efficiency, reduce costs and streamline workflow. If IT is not a core competency of your business, then you need a reliable IT partner to manage the highly complex and fast-moving world of IT.
              </p>
            </details>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container-row">
          <h1>Ready to get started?</h1>
          <p>Get your team the support it needs for daily business activities, 24/7</p>
          <a href="/contact/">
            <button className="btn-white">Contact us now</button>
          </a>
        </div>
      </section>

    

 <section className="contacts" id="contacts">
  <div className="contact-container">
    <div className="contacts-heading">
      <h1 className="heading">Get in touch with us</h1>
      <p className="get-in-touch-text">
        Thank you for your interest in contacting CSR Ltd. Please reach us at the contacts below. We will be happy to discuss this with you.
      </p>
    </div>


    <div className="contact-details-forms">


    <div className="contact-details">
      <div className="contact-column">
        <div className="contact-box">
          <div className="office-title">
            <div className="icon">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <h3>Rwanda Office</h3>
          </div>
          <p>Immeuble Aigle Blanc</p>
          <p>KN 5 Rd, Kigali, Rwanda.</p>
        </div>
        
        <div className="contact-box">
          <div className="office-title">
            <div className="icon">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <h3>USA Office</h3>
          </div>
          <p>2510 E Independence St</p>
          <p>816 E. Federal Shawnee, OK 74804</p>
        </div>
      </div>
      
      <div className="contact-column">
        <div className="contact-box">
          <div className="office-title">
            <div className="icon">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <h3>Email Us</h3>
          </div>
          <p>
            <a href="mailto:info@csrlimited.rw">info@csrlimited.rw</a>
          </p>
          <p>
            <a href="mailto:info@csrlimited.com">info@csrlimited.com</a>
          </p>
        </div>
        
        <div className="contact-box">
          <div className="office-title">
            <div className="icon">
              <i className="fa fa-phone" aria-hidden="true"></i>
            </div>
            <h3>Call Us</h3>
          </div>
          <p>
            <a href="tel:+250791902159">Phone: +250791902159</a>
          </p>
          <p>
            <a href="tel:+4057774097">Phone: 405.777.4097</a>
          </p>
        </div>
      </div>
    </div>
    
    <div className="form-container">
      <form method="POST" className="form-method" id="form">
        <input type="hidden" name="csrfmiddlewaretoken" value="uGYg1p6XV369gmnOFeM5ImdblXPOaSJoxs1shwoYrTx9fz88reEcjZwYYrqvkLJx" />
        <input type="text" name="name" placeholder="Full name" maxlength="255" required id="id_name" />
        <input type="email" name="email" placeholder="Email address" maxlength="255" required id="id_email" />
        <input type="text" name="phone_number" placeholder="Phone number with country code" maxlength="255" required id="id_phone_number" />
        <textarea name="message" cols="40" rows="5" placeholder="Your message" maxlength="1000" required id="id_message"></textarea>
        <input type="submit" value="Send message" className='submit-btn' />
      </form>
    </div>




    </div>
    
 


    
    <div className="location">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.499657960703!2d30.094012214924884!3d-1.9534435372691505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x64e8a36be1f73c77%3A0x13d6066392f7624c!2sComprehensive%20Staffing%20Resources!5e0!3m2!1sen!2srw!4v1676450976959!5m2!1sen!2srw"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</section>


    </section>
    <Footer />
    </>
  );
}

export default Techsupport;

