import React from "react";
import "../../assets/css/pages/graphic-design/graphicdesign.css";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import { Helmet } from "react-helmet";

const GraphicDesignPage = () => {
    return (
        <div>
            <Header />
            <section className="graphic-design-hero">
                <div className="container">
                    <h1 className="heading hero-heading">
                        When typography is on point,<br /> words become images.
                    </h1>
                    <p>Any creative project is unique, our team solves it visually.</p>
                    {/* <div className="hero-btn">
                        <div className="btn">
                            <button className="btn-hero2 ">
                                <a href="/contact/">Get a quotation</a>
                            </button>
                        </div>
                    </div> */}
                </div>
            </section>

      <section className="graphic-design-services">
        <div className="container">
          <h1 className="heading">Our services</h1>
          <p className="section-intro">
            Your brand is expressed in an emotional and genuine manner. Our
            ability to accomplish anything with deftness is based on our
            extensive experience.
          </p>
          <div className="row">
            <div className="col">
              <div className="icon-image">
                <img
                  src="/assets/icons/graphic-design/brand guid.png"
                  alt="Brand guidelines"
                />
              </div>
              <h3>Brand guidelines</h3>
              <p>
                We help you to have an identity which aids in building and
                growing your brand, be it a new brand or you need a rebranding.
              </p>
            </div>
            <div className="col">
              <div className="icon-image">
                <img
                  src="/assets/icons/graphic-design/package.png"
                  alt="Packaging Design"
                />
              </div>
              <h3>Packaging Design</h3>
              <p>
                We create an attractive and functional container for your goods
                which helps your brand to stand out.
              </p>
            </div>
            <div className="col">
              <div className="icon-image">
                <img
                  src="/assets/icons/graphic-design/printing.png"
                  alt="Print Design"
                />
              </div>
              <h3>Print Design</h3>
              <p>
                We design good quality prints for your company which stand out
                in exhibitions or at the office. Example: textbooks, Magazines,
                Billboards prints, and flyers.
              </p>
            </div>
            <div className="col">
              <div className="icon-image">
                <img
                  src="/assets/icons/graphic-design/social media.png"
                  alt="Social Media Content and Distribution"
                />
              </div>
              <h3>Social Media Content and Distribution</h3>
              <p>
                With the help of our Digital marketing department, we come up
                with campaigns and also content which is well visually
                represented through art.
              </p>
            </div>
            <div className="col">
              <div className="icon-image">
                <img
                  src="/assets/icons/graphic-design/content development.png"
                  alt="Concept development and creative strategy"
                />
              </div>
              <h3>Concept development and creative strategy</h3>
              <p>
                We help you to have an end goal for your campaigns through the
                collaboration of our teams in a creative way to reach your
                potential clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="graphic-design-process">
        <div className="container">
          <h1 className="heading">Our Design Process</h1>
          <p className="section-intro">
            We believe that good design is a collaborative process, and we work
            closely with our clients to ensure that the end result is exactly
            what they envisioned.
          </p>
          <div className="row">
            <div className="col">
              <div className="process">1</div>
              <div className="content">
                <h3>Meeting with you</h3>
                <p>
                  We have a meeting with the client to learn about their needs
                  and to generate suggestions.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="process">2</div>
              <div className="content">
                <h3>Research</h3>
                <p>
                  We study the innovative and methodical approaches used by our
                  client's sector to produce excellent results.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="process">3</div>
              <div className="content">
                <h3>Concepts</h3>
                <p>
                  After learning about the sector, we come up with ideas for the
                  message's delivery and the necessary materials. For instance,
                  either a product or a person is being photographed, or both.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="process">4</div>
              <div className="content">
                <h3>Sketch</h3>
                <p>
                  We draft to have a clear idea of how the message will be
                  communicated after integrating the brainstorms.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="process">5</div>
              <div className="content">
                <h3>Presentation with you</h3>
                <p>
                  We present the sketches to the client for comment or approval.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="process">6</div>
              <div className="content">
                <h3>Revise & Delivery</h3>
                <p>
                  We provide the entire project to the client after confirming
                  that it is ready or making the necessary corrections based on
                  feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="graphic-design-projects">
        <div className="container">
          <h1 className="heading">Our latest projects</h1>
          <div className="row">
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/pawhuska.png" />
              </div>
            </div>
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/prague memorial.jpg" />
              </div>
            </div>
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/acclaimed.png" />
              </div>
            </div>
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/fehdah.jpg" />
              </div>
            </div>
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/freight forwarders.png" />
              </div>
            </div>
            <div className="col">
              <div className="image-project">
                <img src="/assets/img/graphic-design/package.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

            <section className="call-to-action">
                <div className="container">
                    <div className="call-content">
                        <h1 className="heading">
                            Would you like unique and innovative designs that will help your brand stand out?
                        </h1>
                        <p>
                            Your brand is expressed in an emotional and genuine manner. Our ability to accomplish anything
                            with deftness is based on our extensive experience.
                        </p>
                        {/* <a href="/contact/">
                            <button className="btn-white btn-call">
                                Get a quotation
                            </button>
                        </a> */}
                    </div>
                </div>
            </section>

      <Footer />
    </div>
  );
};

export default GraphicDesignPage;
