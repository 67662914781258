import React from "react";
import "../../assets/css/pages/social-media/social-media.css";
import Footer from "../../components/footer";
import {SecondHeader} from "../../components/header";

const SocialMediaPage = () => {
    return (
        <div>
            <SecondHeader />
            <section className="hero-social-media">
                <div className="container">
                    <div className="row">
                        <div className="col col-social">
                            <h1 className="heading hero-heading">
                                We are a full-service digital marketing provider that specializes in helping businesses succeed in the digital world.
                            </h1>
                            <p>
                                Be less concerned about your business’s online visibility by entrusting your digital marketing services to a team of experienced hands. You can be certain that through proper online channels, CSR Ltd will improve your online presence leading to global visibility, massive sales, and market extension.
                            </p>
                            <p>
                                This is achievable using social media marketing, content marketing, pay-per-click advertising, email marketing, and other online marketing channels.
                            </p>
                            {/* <div>
                                <a href="/contact/">
                                    <button className="btn-blue btn-social">Get quotation</button>
                                </a>
                            </div> */}
                        </div>
                        <div className="col">
                            <img src="/assets/img/social-media/Mobile%20application.svg" />
                        </div>
                    </div>
                    <div className="social-media">
                        <a href="https://www.instagram.com/csr.rw/">
                            <img id="spin" className="facebook" src="/assets/icons/social-media/logos_facebook.svg" />
                        </a>
                        <a href="https://www.instagram.com/csr.rw/">
                            <img id="spin" className="instagram" src="/assets/icons/social-media/instagram.svg" />
                        </a>
                        <a href="https://www.linkedin.com/company/comprehensive-staffing-resources-ltd/">
                            <img id="spin" className="linkedin" src="/assets/icons/social-media/linkedin.svg" />
                        </a>
                        <a href="https://www.youtube.com/embed/9KcvJozkbSQ">
                            <img id="spin" className="youtube" src="/assets/icons/social-media/logos_youtube-icon.svg" />
                        </a>
                        <a href="https://twitter.com/csr_rw">
                            <img id="spin" className="twitter" src="/assets/icons/social-media/skill-icons_twitter.svg" />
                        </a>
                    </div>
                    <div className="pattern">
                        <img src="/assets/img/social-media/Pattern1%205.svg" />
                    </div>
                </div>
            </section>

            <section className="social-media-services">
                <div className="container">
                    <h1 className="heading">Our services include:</h1>
                    <p>
                        Let us help your business grow and succeed in the digital world. Contact us today
                        <br />
                        to learn more about our services and how we can help your business reach new heights.
                    </p>
                    <div className="row">
                        <div className="col">
                            <div className="icon">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </div>
                            <h2>Search Engine Optimization</h2>
                            <p>
                                We use proven strategies and techniques to improve your website's ranking on search engines like Google, driving more organic traffic to your site.
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                            </div>
                            <h2>Content Marketing</h2>
                            <p>
                                From blog posts and infographics to videos, our team will create engaging and informative content that helps drive traffic to your website and establishes your brand as a top leader in your industry.
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            </div>
                            <h2>Social Media Marketing </h2>
                            <p>
                                We help you build a strong presence on social media platforms such as Facebook, Instagram, and Twitter, engaging with your followers and growing your online community.
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <i className="fa fa-paypal" aria-hidden="true"></i>
                            </div>
                            <h2>Pay-Per-Click Advertising </h2>
                            <p>
                                Our PPC experts will help you create and manage effective advertising campaigns on platforms like Google Ads and Facebook Ads, reaching your target audience.
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <h2>Email Marketing</h2>
                            <p>
                                We create and execute email campaigns that engage with your subscribers and drive conversions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="social-media-section">
                <div className="container">
                    <div className="row">
                        <div className="col col-bottom">
                            <h1 className="heading">Our Clients, Our Priority</h1>
                            <p>
                                At CSR Ltd Digital Marketing Services, we believe in a data-driven approach to marketing, and we
                                are committed to delivering measurable results for our clients. Our team of experts stays
                                up-to-date on the latest industry trends and techniques, ensuring that you receive the most effective and
                                efficient marketing services possible.
                            </p>
                        </div>
                        <div className="col">
                            <img src="/assets/img/social-media/undraw_buffer_wq43%201.svg" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>


    );
}

export default SocialMediaPage;