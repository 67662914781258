import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl } from '../../utils/constants';
import "../../assets/css/unsubscribe/unsub.css";
// import { CheckmarkSquare02Icon, SquareIcon, Loading02Icon } from "hugeicons-react";



const UnSubcriptionForm = () => {
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);

   const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!email) {
            setError('this fields is required');
            return;
        }
        if(!isChecked){
            setError('you have already Unsubscribed');
            return;
        }
        try {
            const UnSubscriptionData = {
                email,
                accepted: isChecked
            };

            const response = await axios.post(`${apiUrl}/subscription/unsubscribe/`, UnSubscriptionData);
            if (response.status === 201) {
                setIsSuccess(true);
                setError('');
                setEmail('');
                setIsChecked(false);

         } else{
            setError('unknown erroe, Try again')
         }
         setTimeout(() => setError(''), 7000); // Clear error message after 7 seconds
        } catch (error) {
            console.error('Error:', error);
        }
        // const handleIsChecked = () => {
        //     setIsChecked(prevChecked => !prevChecked);
        // };
    };
    return (
        <div className='unsubscription'>
            <div className="unsub-b">
                        {/* <img src={'/assets/img/home/logo-blue.png'} alt='Logo' className='logo' /> */}
                        <h2>Unsubscribe Today</h2>
                    </div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="Email">Email</label>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className= "check">
                <label>
                    <input
                        type="checkbox" 
                        // checked={isChecked}
                        // onChange={handleIsChecked}
                    />
                    I agree to the <a href="#">terms and conditions</a>
                </label>
                </div>
                <button type="submit" className='unsub-btn'>Submit</button>
            </form> 
        </div>
    );
};

export default UnSubcriptionForm;
