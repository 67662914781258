import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl } from '../../utils/constants';
import { CheckmarkSquare02Icon, SquareIcon, Loading02Icon } from "hugeicons-react";

const NewSubscriptionForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState("");
    const [isSuccess, setIsSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!email || !phoneNumber) {
            setError('All fields are required');
            return;
        }

        if (!isChecked) {
            setError('Please agree to the terms and conditions');
            return;
        }

        setIsLoading(true);

        try {
            const subscriptionData = {
                email,
                phone_number: phoneNumber,
                accepted: isChecked
            };
            const response = await axios.post(`${apiUrl}/subscription/new/`, subscriptionData);
            if (response.status === 201) {
                setIsLoading(false);
                setError('');
                setEmail('');
                setPhoneNumber('');
                setIsChecked(false);
                setIsSuccess(true);
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                setError(error.response.data.message || error.response.data.error || 'Error while saving your subscription. Try again later');
            } else {
                setError('Unknown error. Try again later');
            }
            setTimeout(() => setError(''), 7000); // Clear error message after 7 seconds
        }
    };

    const handleIsChecked = () => {
        setIsChecked(prevChecked => !prevChecked);
    };

    return (
        <>
            {isSuccess ? (
                <div className="success-card">
                    <h3>Thank you for subscribing!</h3>
                    <p>You have successfully subscribed to our newsletter! Stay tuned for the latest updates.</p>
                    <a href='/' className='btn-blue button'>Back to home</a>
                </div>
            ) : (
                <div className='subscription-form'>
                    <div className="branding">
                        {/* <img src={'/assets/img/home/logo-blue.png'} alt='Logo' className='logo' /> */}
                        <h1>Subscribe Today</h1>
                    </div>
                    {error && <p className='error-message'>{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label htmlFor='email'>Email:</label>
                            <input
                                id='email'
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your Email:'
                                required
                            />
                        </div>
                        <div className="field">
                            <label htmlFor='phoneNumber'>Phone Number:</label>
                            <input
                                id='phoneNumber'
                                type="tel"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder='Enter your Phone Number'
                                required
                            />
                        </div>
                        <div onClick={handleIsChecked} className="checkbox">
                            {isChecked ? <CheckmarkSquare02Icon /> : <SquareIcon />}
                            <span>I want to join your email list</span>
                        </div>
                        <button disabled={isLoading || isSuccess} type='submit' className='btn-blue'>
                            {isLoading ? <Loading02Icon className='loading-icon' size={18} /> : 'Subscribe now'}
                        </button>
                    </form>
                </div>
            )}
        </>
    );
};

export default NewSubscriptionForm;
